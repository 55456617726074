{
  "name": "borgo-kalp-fe",
  "version": "1.2.3-94a63dae.1",
  "scripts": {
    "build": "./scripts/version.sh && ng build",
    "preinstall": "./scripts/configure.sh",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.sparbanken.me",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "coverage": "browser-sync start --server ./coverage/borgo-kalp-fe --watch '*.html'"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.3",
    "@angular/cdk": "^18.0.3",
    "@angular/common": "^18.0.3",
    "@angular/compiler": "^18.0.3",
    "@angular/core": "^18.0.3",
    "@angular/forms": "^18.0.3",
    "@angular/material": "^18.0.3",
    "@angular/platform-browser": "^18.0.3",
    "@angular/platform-browser-dynamic": "^18.0.3",
    "@angular/router": "^18.0.3",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.4",
    "@angular-eslint/builder": "18.0.1",
    "@angular-eslint/eslint-plugin": "18.0.1",
    "@angular-eslint/eslint-plugin-template": "18.0.1",
    "@angular-eslint/schematics": "18.0.1",
    "@angular-eslint/template-parser": "18.0.1",
    "@angular/cli": "^18.0.4",
    "@angular/compiler-cli": "^18.0.3",
    "@angular/material-luxon-adapter": "^18.0.3",
    "@sparbanken-syd/kalpylator": "0.6.3",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^18.1.1",
    "@sparbanken-syd/sparbanken-syd-qr": "^18.1.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^18.0.0",
    "@sparbanken-syd/sparbanken-tax": "^0.5.1",
    "@types/d3": "^7.4.3",
    "@types/jasmine": "~5.1.4",
    "@types/luxon": "^3.3.8",
    "@types/node": "20",
    "@types/sparbanken-syd-auth-backend": "^2.0.1",
    "@types/sparbanken-syd-datastore": "^0.2.0",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "browser-sync": "^3.0.2",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jsdoc": "^48.0.2",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "luxon": "^3.4.4",
    "postcss": "^8.4.32",
    "postcss-scss": "^4.0.9",
    "stream-browserify": "^3.0.0",
    "stylelint": "^16.1.0",
    "stylelint-config-standard-scss": "^13.0.0",
    "stylelint-scss": "^6.0.0",
    "typescript": "5.4.5"
  }
}
