import {APP_INITIALIZER, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {SpbCommonModule} from './common/common.module'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {ConfigService} from './services/config.service'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import '@angular/common/locales/global/fr'
import '@angular/common/locales/global/sv'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core'
import {LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS} from '@angular/material-luxon-adapter'

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SpbCommonModule,
        ThemeModule], providers: [
        WINDOW_PROVIDERS,
        LOCAL_STORAGE_PROVIDERS,
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: (s: ConfigService) => () => s.bootstrap(),
            deps: [ConfigService],
            multi: true
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { floatLabel: 'always', hideRequiredMarker: true, appearance: 'outline' }
        },
        { provide: MAT_DATE_LOCALE, useValue: 'sv-SE' },
        { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true, firstDayOfWeek: 1 } },
        { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    ] })
export class AppModule {
}
